import { createSlice } from '@reduxjs/toolkit';

export const zoomableStore = createSlice({
    name: "zoomable",
    initialState: {
        // [id]: false
    },
    reducers: {
        resetZoom(state, action) {
            Object.keys(state).forEach(id => {
                state[id] = false;
            });
        },
        setZoom(state, action) {
            const {id, value} = action.payload;
            state[id] = value;
        }
    }
});

export const {
    resetZoom,
    setZoom
} = zoomableStore.actions;