import React from 'react';
import IconLunch from "./icons/IconLunch";
import IconCalendar from "./icons/IconCalendar";
import IconBreak from "./icons/IconBreak";
import IconEmail from "./icons/IconEmail";
import IconQuestion from "./icons/IconQuestion";
import IconTime from "./icons/IconTime";
import IconAccount from "./icons/IconAccount";
import IconCaretDown from "./icons/IconCaretDown";
import IconCaretUp from "./icons/IconCaretUp";
import IconClose from "./icons/IconClose";
import IconChat from "./icons/IconChat";
import IconCheck from "./icons/IconCheck";
import IconArrowDown from "./icons/IconArrowDown";
import IconArrowUp from "./icons/IconArrowUp";
import IconView from "./icons/IconView";
import IconDownload from "./icons/IconDownload";
import IconPlay from "./icons/IconPlay";
import IconPause from "./icons/IconPause";
import IconMaximise from "./icons/IconMaximise";
import IconMinimise from "./icons/IconMinimise";
import IconRotateCcw from "./icons/IconRotateCcw";
import IconRotateCw from "./icons/IconRotateCw";
import IconArrowSx from "./icons/IconArrowSx";
import IconArrowDx from "./icons/IconArrowDx";
import IconLens from "./icons/IconLens";
import IconLangPlayer from "./icons/IconLangPlayer";
import IconExpand from "./icons/IconExpand";
// TODO: Componente container di svgs per icone, lavori in corso
const WipIcon = ({type,size='25px'}) => {
    return (
        <span className='wip-icon'>
            {/*Lunch*/}
            {type === 'lunch'?<IconLunch size={size}/>:null}
            {/*Calendar*/}
            {type === 'calendar'?<IconCalendar size={size}/>:null}
            {/*Break*/}
            {type === 'break'?<IconBreak size={size}/>:null}
            {/*Email*/}
            {type === 'email'?<IconEmail size={size}/>:null}
            {/*thick*/}
            {type === 'thick'?<IconQuestion size={size}/>:null}
            {/*Time*/}
            {type === 'time'?<IconTime size={size}/>:null}
            {/*Account*/}
            {type === 'account'?<IconAccount size={size}/>:null}
            {/*Caret Down*/}
            {type === 'caret-down'?<IconCaretDown size={size}/>:null}
            {/*Caret Up*/}
            {type === 'caret-up'?<IconCaretUp size={size}/>:null}
            {/*Close*/}
            {type === 'close' ? <IconClose size={size}/> : null }
            {/*Chat*/}
            {type === 'chat' ? <IconChat size={size}/> : null }
            {/*Question mark*/}
            {type === 'question' ? <IconQuestion size={size}/> : null }
            {/*Check*/}
            {type === 'check' ? <IconCheck size={size}/> : null }
          {/*Arrow Down*/}
          {type === 'arrow-down' ? <IconArrowDown size={size}/> : null }
          {/*Arrow Up*/}
          {type === 'arrow-up' ? <IconArrowUp size={size}/> : null }
            {/*View (eye)*/}
            {type === 'view' ? <IconView size={size}/> : null }
          {/*Pause*/}
          {type === 'pause' ? <IconPause size={size}/> : null }
          {/*Play*/}
          {type === 'play' ? <IconPlay size={size}/> : null }
          {/*Pause*/}
          {type === 'maximise' ? <IconMaximise size={size}/> : null }
          {/*Pause*/}
          {type === 'minimise' ? <IconMinimise size={size}/> : null }
            {/*Download*/}
            {type === 'download' ? <IconDownload size={size}/> : null }
            {/* EN lang player select*/}
            {type === 'player-lang' ? <IconLangPlayer size={size}/> : null }
            {/* Expand */}
            {type === 'expand' ? <IconExpand size={size}/> : null }
            {/*Arrow Sx*/}
            {type === 'arrow-sx' ? <IconArrowSx size={size}/> : null }
          {/*Arrow Dx*/}
          {type === 'arrow-dx' ? <IconArrowDx size={size}/> : null }
            {/*Rotate cw*/}
            {type === 'rotate-cw' ? <IconRotateCw size={size}/> : null }
          {/*Rotate ccw*/}
          {type === 'rotate-ccw' ? <IconRotateCcw size={size}/> : null }
             {/*Lens (zoom/search) */}
            {type === 'lens' ? <IconLens size={size}/> : null }
        </span>
    )
};

export default WipIcon;
