import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import { serializeError } from 'serialize-error';
import { v4 as uuidv4 } from 'uuid';

export const fetchData = createAsyncThunk(
    'exhibitionGallery/fetchData',
    async ({...payload}, {rejectWithValue}) => {
        try {
            const res = await Axios.get('/api/exhibition');
            return res.data;
        } catch (err) {
            const serr = serializeError(err);
            if (err.isAxiosError) {
                return rejectWithValue(serr);
            }
            throw err;
        }
    }
);

export const exhibitionGalleryStore = createSlice({
    name: 'exhibitionGallery',
    initialState: {
        fetched: false,
        cursor: "", // ""|"up"|"down"|"left"|"right"|"zoom"
        slide: {
            width: -1,
            height: -1,
        },
        navigation: {
            map: false,
            x: -1,
            y: -1,
        },
        popupText:'',
        trans: {},
        schools: [
            // {
            //     name: "bologna",
            //     city: "bologna",
            //     slides: [
            //         {
            //             type: "intro|text|360|carousel",
            //             link: "top|bottom|both",
            //             id: "id",
            //
            //             // intro
            //             image: {
            //                 desktop: "url",
            //                 mobile: "url",
            //             },
            //             text: "",
            //             abstract: "",
            //             author: "",
            //             author_role: "",
            //             goto: 0, // slides index
            //
            //             // text
            //             text: "",
            //
            //             // 360
            //             image: {
            //                 desktop: "url",
            //                 mobile: "url",
            //             },
            //             author: "",
            //             title: "",
            //             description: "",
            //
            //             // carousel
            //             author: "",
            //             title: "",
            //             description: "",
            //             items: [
            //                {
            //                    type: "image|video",
            //
            //                    // image
            //                    image: {
            //                        desktop: "url",
            //                        mobile: "url",
            //                    }
            //
            //                    // video
            //                    thumbnail: {
            //                        desktop: "url",
            //                        mobile: "url",
            //                    },
            //                    video: {
            //                        desktop: {
            //                            mp4: "url",
            //                            ogg: "url",
            //                            default: "url"
            //                        },
            //                        mobile: {
            //                            mp4: "url",
            //                            ogg: "url",
            //                            default: "url"
            //                        },
            //                    }
            //                },
            //                ...
            //             ]
            //         },
            //         ...
            //     ]
            // },
            // ...
        ]
    },
    reducers: {
        setCursor(state, action) {
            state.cursor = action.payload;
        },

        setSlide(state, action) {
            state.slide = action.payload;
        },

        navMap(state, action) {
            state.navigation.map = action.payload;
        },

        navJump(state, action) {
            const { x, y } = action.payload;
            const ny = Math.max(0, Math.min(y, state.schools.length));
            const sc = state.schools[ny];
            const nx = Math.max(0, Math.min(x, sc.slides.length));
            state.navigation.y = ny;
            state.navigation.x = nx;
        },

        navNextSchool(state) {
            const { y, x } = state.navigation;
            const slide = state.schools[y].slides[x];
            const linked = ["both", "bottom"].indexOf(slide.link) !== -1;
            state.navigation.y = Math.min(y + 1, state.schools.length - 1);
            state.navigation.x = linked ? x : 0;
            if (state.navigation.y === state.schools.length - 1) {
                state.cursor = "";
            }
        },

        navPrevSchool(state) {
            const { y, x } = state.navigation;
            const slide = state.schools[y].slides[x];
            const linked = ["both", "top"].indexOf(slide.link) !== -1;
            state.navigation.y = Math.max(0, y - 1);
            state.navigation.x = linked ? x : 0;
            if (state.navigation.y === 0) {
                state.cursor = "";
            }
        },

        navNextSlide(state) {
            const { x, y } = state.navigation;
            const slides = state.schools[y]?.slides || [];
            state.navigation.x = Math.min(x + 1, slides.length - 1);
            if (state.navigation.x === slides.length - 1) {
                state.cursor = "";
            }
        },

        navPrevSlide(state) {
            const { x, y } = state.navigation;
            const slides = state.schools[y]?.slides || [];
            state.navigation.x = Math.max(0, x - 1);
            if (state.navigation.x === 0) {
                state.cursor = "";
            }
        },
    },
    extraReducers: {
        [fetchData.fulfilled]: (state, action) => {
            const {schools = [], trans} = action.payload;
            state.fetched = true;
            state.navigation = {
                map: false,
                x: 0,
                y: 0
            };
            state.trans = trans;
            schools.forEach(sc => {
                sc.slides.forEach(sl => {
                    sl.id = sl.id || uuidv4();
                    if (sl.type === "carousel") {
                        try {
                            sl.items
                                .filter(i => i.type === "image")
                                .forEach(i => {
                                    i.id = uuidv4();
                                });
                        } catch (e) {
                            console.log(sc.name, sl.id);
                            throw e
                        }
                    }
                });
            });
            state.schools = schools;
        }
    }
});

export const {
    setCursor,
    setSlide,
    navMap,
    navJump,
    navNextSchool,
    navPrevSchool,
    navNextSlide,
    navPrevSlide,
} = exhibitionGalleryStore.actions;

